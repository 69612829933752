.firma-page {
    min-height: 100vh;
}

.firma-area {
    border-color: black;
    border-style: solid;
}

.firma-button-area {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.firma-preview {
    max-width: 100%;
}

.confirm-sign-i {
    color: #67ad67;
    font-size: 111px;
}

.contract-table {
    th {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 3 !important;
        padding-right: 3 !important;
        border: 1px solid;
        border-top: 1px solid !important;
    }
    td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 3 !important;
        padding-right: 3 !important;
        border: 1px solid;
        border-top: 1px solid !important;
    }
}

.conLogo {
    margin-top: 35px;
}

.tableUp {
    margin-top: -17px;
}

@media (min-width: 800px) {
    .container-signature {
        margin-left: 10%;
        margin-right: 10%;
    }

    .name-signature-input {
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
}

@media (max-width: 800px) {
    .container-signature {
        margin-left: 2%;
        margin-right: 2%;
    }

    .name-signature-input {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}
