@import 'variables.scss';
@import 'navbar.scss';
@import 'dashboard.scss';
@import 'modal.scss';
@import 'login.scss';
@import 'firma.scss';
@import 'loader.scss';
@import 'tables.scss';
@import 'signature.scss';
@import 'icons.scss';
@import 'rates.scss';

.pl-0 {
    padding-left: 0;
}

.app {
    background-color: $bg-color;
    //min-height: 100vh;
}

.page-body {
    background-color: $bg-color;
    min-height: calc(100vh - 80px);
    margin-top: 60px;
    padding-top:5px;
}

.active-padding {
    padding-left: 200px;
    transition: 350ms;
}

.inactive-padding {
    padding-left: 30px;
    transition: 350ms;
}

.active-padding {
    padding-left: 200px;
    transition: 350ms;
}

.tac {
    text-align: center;
}

input[type='file'] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pointer {
    cursor: pointer;
}

.center-document {
    img {
        width: 50% !important;
        height: auto !important;
        margin-left: auto;
        margin-right: auto;
    }
}

.min-vh-100 {
    min-height: 100vh;
}

.full-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.text-red {
    color: red;
}

.center-50 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.center-90 {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bold {
    font-weight: bold;
}

.center-fit {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
}

.center-flex {
    display: flex;
    justify-content: center;
}

.full-center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-primary {
    background-color: $main-color;
    border-color: $main-color;
    &:hover {
        background-color: $main-hover;
        border-color: $main-color;
    }
    &:focus {
        background-color: $main-hover;
        border-color: $main-color;
        box-shadow: none;
    }
}

a {
    color: $main-color;
}

.nav-link {
    &:hover {
        color: $main-hover;
    }
}

.circle-list {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border-color: silver 1px solid;
    margin-right: 10px;
}

.btn-link {
    font-weight: 400;
    color: $main-color;
    text-decoration: none;
    &:hover {
        color: $main-hover;
    }
    &:focus {
        box-shadow: 0px 0px 3px 1px rgba(131, 13, 13, 1);
    }
}

.card .collapse{max-height:200px !important;overflow-y:auto}
.react-responsive-modal-closeButton{top:20px !important;right:22px !important;color:#8890B5}
.iwxbez{min-height: 12px !important;}