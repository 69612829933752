.login-page {
    min-width: 100vw;
    min-height: 100vh;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-card {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    min-height: 270px;
    border-radius: 10px;
    background-color: $main-color;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(131, 13, 13, 1);
    -moz-box-shadow: 0px 0px 10px 1px rgba(131, 13, 13, 1);
    box-shadow: 0px 0px 10px 1px rgba(131, 13, 13, 1);
}

.login-logo {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-top: 20px;
}

.loginform label{
    color:$basic-font-color;
}

.frontimage{
      background: url("https://i.ibb.co/RhMZprS/jony-Image2-1.png") no-repeat center center scroll;
      background-size: cover;
      height: 100vh;
  width: 1194px;
}

.tiltelogin{
    color:$nav-item-bg-color;
    font-weight:bold;
    text-align:center;
    margin-bottom:50px;
}