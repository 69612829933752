.rate-comment {
    padding: 10px;
    border: 1px dashed;
    border-radius: 15px;
}

.rate-question-section {
    padding: 15px;
    border: 1px dashed;
    border-radius: 15px;
}
