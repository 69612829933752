.container-page {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    padding: 15px;
}

.page-title {
    color: $page-title-color;
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 800;
    text-transform: uppercase;
}

.page-search {
    width: 400px;
}

.chart-card {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
    padding: 10px;
    background-color: white;
    .title {
        color: $page-title-color;
    }
}

.number-card {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 7px 0 0 0;
    background-color: white;
    .number {
        font-size: 30px;
    }
    .title {
        color: $page-title-color;
        font-size: 30px;
    }
    footer {
        display: flex;
        align-items: center;
    }
    .green-icon {
        margin: 5px;
        color: green;
    }
    .yellow-icon {
        margin: 5px;
        color: gold;
    }
    .red-icon {
        margin: 5px;
        color: #b41616;
    }
}

.ranking-card {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 0 0 10px 0;
    background-color: white;
    border-radius: 15px;
    .title-area {
        border-radius: 15px 15px 0 0;
        display: flex;
        font-size: 20px !important;
        background-color: #f1f1f1;
        padding: 10px 10px 0 10px;
        h6 {
            font-size: 20px;
        }
        .title {
            color: $page-title-color;
        }

        svg {
            margin-left: auto;
            color: gold;
        }
    }
}

.link-card {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 5px;
    background-color: white;
    border-radius: 5px;
}

.number-section {
    font-size: 16px;
    text-align: center;
}
.list-data table{font-size:10px}