.nav-icon {
    position: relative;
    top: -1px;
    height:26px;
}

.close-nav-icon {
    color: white;
}

.open-nav-icon {
    color: $basic-font-color;
}

.profile-button {
    color: white;
    font-size: 25px;
    &:hover {
        color: white;
    }
}

.profile-position {
    position: fixed;
    right: 45px;
    .dropdown-menu {
        //left: -80px;
    }
}

.navbar {
    background-color: $header-color;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: 999;
    border-bottom: 2px solid #E8E8E8;
    color: $basic-font-color;
}

.menu-bars {
    margin-left: 1rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: $nav-color;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    transition: 350ms;

}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-menu.close {
    width: 70px;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 8px 8px 16px;
    list-style: none;
    height: 60px;
    line-height: 16px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    background-color: $nav-item-bg-color;
}

.nav-text a:hover {
    background-color: $main-hover;
}

.nav-menu-items {
    width: 100%;
    padding-left: 0;
}

.navbar-toggle {
    background-color: $header-color;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.nav-span {
    margin-left: 16px;
}

.nav-logo {
    height: 135%;
    margin-left:25px;
    margin-top: -13px;
}

.nav-user-name {
    
    font-size: 15px;
    font-weight:bold;
    position: relative;
    top: 2px;
}

.navbar-count {
    width: 100%;
    align-items: center;
    padding: 0 16px 0 14px;
    .box {
        padding: 10px;
        border: 1px dashed black;
        border-radius: 15px;
    }
}

.active-padding-nav {
    position: relative;
    left: 2px;
    transition: 350ms;
}
/*
.inactive-padding-nav {
    position: relative;
    left: -11%;
    transition: 350ms;
}
*/

.dropdown-menu,.dropdown-menu button{
    background-color:$header-color;
    color: $basic-font-color;
}
.profile-button{
    color: $basic-font-color;
}

.litems > li a.active {
    color: #F65365;
    background: #F8FAFC;
    border-radius: 15px;
}

.litems> li {
    
    background: transparent;
    border-radius: 0 0 4px 4px;
    margin-left: 20px;       
    list-style: none;       
    text-align: -webkit-match-parent;
    padding: 1px;
}
.litems> li a{
            color:#8890B5;            
            margin:10px;
            text-transform: capitalize;
            font-size: 12px;
            width:100%;
            display:grid;
            grid-template-columns: 24px auto;
            
            align-items: center;
            }
.litems > li a:hover {    
    text-decoration: none;
}
.litems > li:hover {
    color: #F65365 !important;
    background: #F6F7FB;    
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
} 

.nav-menu.close ul li a span{
    display: none;
}

.toast-session{
z-index:998;
position: absolute; top: 60px; right: 0;
max-width: 450px;

}
.toast-session .toast-header{
    padding: 1px !important;    
}
.toast-session .toast-header button{
    font-size:12px
}