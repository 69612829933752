@media (max-width: 600px) {
    .modal-size {
        width: 90vw;
        border-radius: 10px;
        max-height: 95vh;
        display: block !important;
    }
}

@media (min-width: 600px) {
    .modal-size {
        width: 800px;
        border-radius: 10px;
    }

    .modal-report {
        width: 1000px;
        max-width: 1000px !important;
        border-radius: 10px;
    }
}

.modal-size {
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.modal-signature {
    position: fixed;
    margin: 0;
    padding: 0;
    width: 100vw;
    top: 0;
    left: 0;
}

.modal-title {
    color: $basic-font-color;
    background-color: $bg-modal-color;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
}

.modal-subtitle {
    font-size: 18px;
    text-decoration: underline;
}

.separator {
    border: 1px solid black;
}

.react-pdf__Page__canvas {
    width: 50% !important;
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
}
