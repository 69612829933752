.table-icon {
    font-size: 16px;
    margin: 5px;
    color: black;
}

.chalkboard-icon {
    position: relative;
    top: 2px;
}

.activities-table {
    border-style: solid;
    border-width: 2px;
    text-align: center;
}

.rdt_TableCol {
    justify-content: center;
    &:nth-child(2n) {
        background: #f5f5f5;
    }
}

.rdt_TableCell:nth-child(2n) {
    background: #f5f5f5;
}

.table-image {
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
