.signature-area {
    top: 0;
    left: 0;
    position: fixed !important;
    width: 100vw;
    height: 100%;
    background: white;
    z-index: 1;
}

@media only screen and (orientation: landscape) {
    .eraser-signature-icon {
        position: fixed;
        top: 86vh;
        left: 10vw;
        font-size: 50px;
        color: #4674b5;
        cursor: pointer;
    }

    .confirm-signature-icon {
        position: fixed;
        top: 86vh;
        left: 80vw;
        font-size: 50px;
        color: green;
        cursor: pointer;
    }
}

@media only screen and (orientation: portrait) {
    .eraser-signature-icon {
        position: fixed;
        top: 90vh;
        left: 10vw;
        font-size: 50px;
        color: #4674b5;
        cursor: pointer;
    }

    .confirm-signature-icon {
        position: fixed;
        top: 90vh;
        left: 80vw;
        font-size: 50px;
        color: green;
        cursor: pointer;
    }
}

@media (min-width: 600px) {
    .signature-details-m {
        margin-left: 30%;
    }

    .signature-details-m2 {
        margin-left: 14.2%;
    }
}

@media (max-width: 600px) {
    .signature-details-m {
        text-align: center;
    }
    .signature-details-m2 {
        text-align: center;
    }
}

.signature-border {
    border-bottom: 1px solid black;
}
